import React from 'react'

import { ProductSearchResultsPage } from '../components'

class Zoeken extends React.Component {
  render() {
    return <ProductSearchResultsPage />
  }
}

export default Zoeken
